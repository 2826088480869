















































































































































































































































































































































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, computed } from "@vue/composition-api";
import {
  AtomButton,
  AtomButtonSizeEnum,
  AtomGraphic,
  AtomSwitcher,
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
  MolBackNav,
  MolGuideLink,
  MolGuideLinkArticleEnum,
  MolInfoLock,
  OrgInfoLockButton,
} from "@/v2/new-design-system";
import { IOutletSidebarProps } from "./props";
import { OrderingServiceOutletEnum } from "@/v2/enum/ordering-service.enum";
import { VIEW_OUTLET_DINE_IN_PAYMENTS } from "@/utils/enums/permissions";
import { useStore } from "@/store";
import { t } from "@/i18n";

const css = bemBuilder("outlet-sidebar");

enum SideBarRoutes {
  DELIVERY_AREAS = "outlets.delivery-areas",
  DELIVERY_AREAS_CREATE = "outlets.delivery-areas.create",
  DELIVERY_AREAS_EDIT = "outlets.delivery-areas.edit",
  INDEX = "outlets.index",
  EDIT = "outlets.edit",
  CREATE = "outlets.create",
  ADDRESS = "outlets.address",
  PICK_UP = "outlets.ordering-services.pickup",
  DELIVERY = "outlets.ordering-services.delivery",
  DINE_IN = "outlets.ordering-services.dine_in",
  DINE_IN_PAYMENTS = "outlets.dine-in-payments",
}

export default defineComponent<IOutletSidebarProps>({
  name: "OutletSidebar",
  components: {
    AtomButton,
    AtomGraphic,
    AtomText,
    MolBackNav,
    MolGuideLink,
    MolInfoLock,
    AtomSwitcher,
    OrgInfoLockButton,
  },
  props: {
    outletName: {
      type: String,
      required: true,
    },
    currentRoute: {
      type: String,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      required: true,
    },
    published: {
      type: Boolean,
      required: true,
    },
    basicInfoComplete: {
      type: Boolean,
      required: true,
    },
    addressComplete: {
      type: Boolean,
      required: true,
    },
    isDeliveryModeEnabled: {
      type: Boolean,
      required: true,
    },
    orderingModesComplete: {
      type: Boolean,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    locked: {
      type: Boolean,
      required: true,
    },
    onActive: {
      type: Function,
      default: () => {},
    },
    onPublish: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { getters } = useStore();

    const canSeeDineInPayments = computed(() => {
      const permissions = getters["auth/getPermissions"];
      return permissions.includes(VIEW_OUTLET_DINE_IN_PAYMENTS);
    });

    function getCheckmarkGraphic(
      isDone: string,
      currentRoute: string,
      linkRoute: Array<String> = []
    ) {
      return isDone
        ? "check-complete"
        : linkRoute.includes(currentRoute)
        ? "check-current"
        : "check-incomplete";
    }

    function isCurrentRoute(currentRoute: string, routes: Array<string>) {
      return routes.includes(currentRoute);
    }

    const hasCompletedOutletSetup = computed(() => {
      return (
        props.basicInfoComplete &&
        props.addressComplete &&
        props.orderingModesComplete
      );
    });

    const canPublish = computed(() => {
      return !props.locked && hasCompletedOutletSetup.value;
    });

    const routeBuilder = (name: string, extraParams = {}) => {
      if (!props.outletId) return {};

      return {
        name,
        params: {
          ...{
            businessId: props.businessId,
            outletId: props.outletId,
          },
          ...extraParams,
        },
      };
    };

    return {
      t,
      css,
      routeBuilder,
      isCurrentRoute,
      OrderingServiceOutletEnum,
      getCheckmarkGraphic,
      canPublish,
      canSeeDineInPayments,
      hasCompletedOutletSetup,
      SideBarRoutes,
      MolGuideLinkArticleEnum,
      AtomTextColorEnum,
      AtomTextTypeEnum,
      AtomButtonSizeEnum,
    };
  },
});
