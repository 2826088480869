import { graphql } from "../graphql";
import mutation from "./mutation.gql";
import { RepoErrorEnum } from "../repo-error.enum";
import { OutletStateEnum } from "@/v2/enum";
import { graphqlExtractCustomResponse } from "@/v2/util/graphql-extract-custom-response";

export async function updateOutletStatus(
  outletId: string,
  state: OutletStateEnum
): Promise<boolean> {
  let response: any;

  try {
    response = await graphql.mutate({
      mutation,
      variables: {
        outletId,
        state,
      },
    });
  } catch {
    throw new Error(RepoErrorEnum.NETWORK_ERROR);
  }

  const extractedResponse = graphqlExtractCustomResponse(
    response.data.changeOutletState
  );

  if (extractedResponse.errors) {
    throw new Error(extractedResponse.errors.code);
  }

  return Boolean(extractedResponse.status);
}
