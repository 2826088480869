




























import UIState from "@/modules/outlets/state/ui";
import GET_OUTLET_STATE from "@/modules/outlets/graphql/GetOutletState.gql";
import { LoadingZone, Toast } from "@/design-system";
import {
  defineComponent,
  onMounted,
  computed,
  onBeforeUnmount,
  ref,
  watch,
  getCurrentInstance,
} from "@vue/composition-api";
import { OrderingModes, OutletStates } from "@/modules/outlets/enums";
import { OutletSidebar } from "@/v2/module/outlet/sidebar";
import { deliveryAreasGetState } from "@/v2/module/delivery-areas/state";
import { fetchOutletCoveredAreas } from "@/v2/repo/outlet/fetch-outlet-covered-areas";
import PUBLISH_OUTLET from "@/modules/outlets/graphql/PublishOutlet.gql";
import globalStore from "@/store";
import { t } from "@/i18n";
import { fetchOutletName } from "@/v2/repo/fetch-outlet-name";
import { outletsTrack } from "@/v2/module/outlet/track";
import { EventEnum } from "@/v2/enum";
import { updateOutletStatus } from "@/v2/repo/update-outlet-status";
import { RepoErrorOutletEnum } from "@/v2/repo/repo-error.enum";

export default defineComponent({
  name: "Outlet",
  components: {
    LoadingZone,
    OutletSidebar,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    outletId: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const currentInstance = getCurrentInstance();

    const {
      setAddressId,
      setOutletState,
      setOutletId,
      setPickupModeState,
      setDeliveryModeState,
      setDineInModeState,
      resetState,
      isOutletInDraft,
      isOutletActive,
      isAddressCreated,
      isDeliveryEnabled,
      isPickupEnabled,
      isDineInEnabled,
      attachedAreasAmount,
      canBePublished,
      setAttachedAreasAmount,
      setOutletName,
      outletName,
      isLocked,
      updateLockState,
      setIsLocked,
    } = UIState;

    // @ts-ignore
    const analytics = currentInstance?.proxy.$analytics;

    const currentRoute = ref("");

    const orderingModesCompletionRule = computed(() => {
      let deliveryAreasComplete = false;

      if (isDeliveryEnabled.value) {
        deliveryAreasComplete = attachedAreasAmount.value > 0;
      }

      return (
        deliveryAreasComplete || isPickupEnabled.value || isDineInEnabled.value
      );
    });

    watch(
      () => currentInstance?.proxy.$route,
      (route) => {
        currentRoute.value = route?.name || "";
      },
      { immediate: true }
    );

    onMounted(async () => {
      if (props.outletId) {
        setOutletName(await fetchOutletName(props.outletId));
      }

      updateLockState(props.businessId);

      if (isDeliveryEnabled.value) {
        await refreshAmountOfAreas();
      }
    });

    watch(
      () => isDeliveryEnabled.value,
      async (isEnabled) => {
        if (!isEnabled) return;
        await refreshAmountOfAreas();
      }
    );

    watch(
      () => deliveryAreasGetState("totalAttachedAreas").value,
      (deliveryAreas) => setAttachedAreasAmount(deliveryAreas)
    );

    const refreshAmountOfAreas = async () => {
      const areas = await fetchOutletCoveredAreas({
        businessId: props.businessId,
        outletId: props.outletId,
      });
      setAttachedAreasAmount(areas.data.length);
    };

    const toggleActiveState = async (isActive: boolean) => {
      const newState = isActive ? OutletStates.ACTIVE : OutletStates.INACTIVE;
      const oldState = isActive ? OutletStates.INACTIVE : OutletStates.ACTIVE;

      try {
        setOutletState(newState);

        if (newState === OutletStates.INACTIVE) {
          setIsLocked(false);
        }

        // @ts-ignore
        await updateOutletStatus(props.outletId, newState);

        new Toast().create({
          type: "success",
          title: t("label.success"),
          text: t("message.outlet_status_switched"),
        });

        outletsTrack(
          analytics,
          EventEnum.OUTLET_STATE_UPDATE,
          props.businessId,
          {
            state: newState,
            outlet_name: outletName.value,
          }
        );
      } catch (error: any) {
        const mapError: { [key: string]: string } = {
          [RepoErrorOutletEnum.ALLOWED_OUTLETS_EXCEEDED]: t(
            "c.outlets.outlet_active_limit"
          ),
          [RepoErrorOutletEnum.MISSING_VALID_ADDRESS]: t(
            "c.outlets.outlet_valid_address"
          ),
          [RepoErrorOutletEnum.MISSING_ACTIVE_ORDERING_MODE]: t(
            "c.outlets.outlet_active_ordering_mode"
          ),
        };

        new Toast().create({
          type: "error",
          title: t("label.oops"),
          text: mapError[error.message] || t("message.general_failure"),
        });

        setOutletState(oldState);
      } finally {
        updateLockState(props.businessId);
      }
    };

    const { dispatch } = globalStore;
    const refreshOutletsState = async () => {
      await dispatch("outlets/fetch");
    };

    const isPublishingOutlet = ref(false);

    const publish = async () => {
      isPublishingOutlet.value = true;

      try {
        await context.root.$apollo.mutate({
          mutation: PUBLISH_OUTLET,
          errorPolicy: "none",
          variables: {
            outletId: props.outletId,
          },
        });

        outletsTrack(analytics, EventEnum.OUTLET_PUBLISHED, props.businessId, {
          outlet_name: outletName.value,
        });

        refreshOutletsState();

        setOutletState(OutletStates.ACTIVE);

        updateLockState(props.businessId);

        const {
          // @ts-ignore
          isOnboardingStepCompleted,
          // @ts-ignore
          isOnboarding,
        } = currentInstance?.proxy;

        const name =
          !isOnboardingStepCompleted && isOnboarding
            ? "onboarding"
            : "outlets.index";

        context.root.$router.push({
          name,
          params: {
            businessId: props.businessId,
          },
        });

        new Toast().create({
          type: "success",
          title: t("label.success"),
          text: t("c.outlets.outlet_published"),
        });
      } catch (e) {
        updateLockState(props.businessId);

        new Toast().create({
          type: "error",
          title: t("label.oops"),
          text: t("message.general_failure"),
        });
      } finally {
        isPublishingOutlet.value = false;
      }
    };

    const initState = (outlet: Record<string, any>) => {
      setAddressId(outlet.address?.id ?? "");
      setOutletId(outlet.id);
      setOutletState(outlet.state);
      setDeliveryModeState(setServiceModeState(outlet, OrderingModes.DELIVERY));
      setPickupModeState(setServiceModeState(outlet, OrderingModes.PICKUP));
      setDineInModeState(setServiceModeState(outlet, OrderingModes.DINE_IN));

      function setServiceModeState(
        outlet: Record<string, any>,
        OrderingMode: OrderingModes
      ) {
        return outlet.orderingServices.find(
          (orderingService: Record<string, string>) =>
            orderingService.slug === OrderingMode
        )?.state;
      }
    };

    const isLoading = ref<boolean>(false);
    const isCreatingOutlet = computed(() => props.outletId === null);
    onMounted(async () => {
      if (!isCreatingOutlet.value) {
        isLoading.value = true;

        // TODO: replace by apollo-composable when available
        const { data } = await context.root.$apollo.query({
          query: GET_OUTLET_STATE,
          variables: {
            outletId: props.outletId,
          },
        });

        isLoading.value = false;

        initState(data.outlet ?? {});
      }
    });

    onBeforeUnmount(() => {
      resetState();
    });

    return {
      isOutletInDraft,
      isOutletActive,
      isAddressCreated,
      isPickupEnabled,
      isDineInEnabled,
      isDeliveryEnabled,
      isLocked,
      isPublishingOutlet,
      attachedAreasAmount,
      canBePublished,
      toggleActiveState,
      currentRoute,
      orderingModesCompletionRule,
      outletName,
      publish,
      isLoading,
    };
  },
});
